import type { MutationFunctionOptions } from '@apollo/client';
import { useProgram } from '@drugfreesleep/app/(program)/course/program.gql';
import { useCallback } from 'react';

import { useLogger } from '../analytics/useImpression';
import type {
  Exact,
  UserRegistrationMutation,
} from '../graphql/generated-types';
import {
  SubscriptionStateEnum,
  useCreateSubscriptionMutation,
  useUserRegistrationMutation,
} from '../graphql/generated-types';
import { useAuth } from './useAuth';

export interface RegistrationFormValues {
  username: string;
  password: string;
  remember: boolean;
}

// return type for the hook that matches useUserRegistrationMutation
type RegistrationMutation = ReturnType<typeof useUserRegistrationMutation>;

export const useRegistration = (): RegistrationMutation => {
  const log = useLogger();
  const auth = useAuth();
  const { program } = useProgram([]);
  const [register, { loading, error, ...rest }] = useUserRegistrationMutation();
  const [subscribe, { loading: sloading, error: sError }] =
    useCreateSubscriptionMutation();

  const onSubmit = useCallback(
    async (
      options?: MutationFunctionOptions<
        UserRegistrationMutation,
        Exact<{
          username: string;
          password: string;
        }>
      >
    ) => {
      const { data, errors } = await register(options);
      const allErrors = [...(errors || [])];
      if (
        data?.registerUser &&
        data?.registerUser?.user?.id &&
        data?.registerUser?.user?.username &&
        data?.registerUser?.jwt
      ) {
        // set user
        auth.login(data?.registerUser.user, data?.registerUser.jwt);
        try {
          // create subscription
          await subscribe({
            variables: {
              input: {
                subscription: {
                  type: 'open',
                  state: SubscriptionStateEnum.Active,
                  userId: data?.registerUser.user.id,
                  programId: program?.id,
                  orgId: program?.orgId,
                  meta: {
                    url: window.location.href,
                  },
                },
              },
            },
          });
        } catch (e) {
          await log('subscription error', {
            subtype: 'error',
            payload: {
              message: 'Error creating subscription',
              error: e,
            },
          });
        }
      }
      return {
        data,
        loading,
        errors: allErrors.length === 0 ? undefined : allErrors,
      };
    },
    [program]
  );

  return [
    onSubmit,
    { loading: loading || sloading, error: error || sError, ...rest },
  ];
};
