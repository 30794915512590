import { gql } from '@apollo/client';
import { useConfig } from '@drugfreesleep/config/useConfig';
import { useProgramWithPayloadQuery } from '@drugfreesleep/modules/graphql/generated-types';

export const useProgram = (sections: string[]) => {
  const config = useConfig();
  const { data, loading, error } = useProgramWithPayloadQuery({
    variables: {
      organizationSlug: config?.program.organizationSlug,
      programSlug: config?.program.slug,
      sections,
      versionId: config?.program.versionId,
    },
    skip: !config,
  });
  return {
    program: data?.programWithPayload?.program,
    interactions: data?.programWithPayload?.interactions,
    loading,
    error,
  };
};

// export const ProgramAtVersion = gql`
//   query ProgramAtVersion($id: Int!, $versionId: Int!) {
//     program(id: $id) {
//       __typename
//       id
//       userId
//       orgId
//       type
//       name
//       slug
//       payload
//       meta
//       isPublic
//       createdAt
//       updatedAt
//       atVersion(input: { versionId: $versionId }) {
//         id
//         userId
//         orgId
//         type
//         name
//         slug
//         payload
//         meta
//         isPublic
//         createdAt
//         updatedAt
//       }
//     }
//   }
// `;
export const Program = gql`
  query Program($id: Int!) {
    program(id: $id) {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
`;

export const ProgramWithPayload = gql`
  query ProgramWithPayload(
    $organizationSlug: String!
    $programSlug: String!
    $sections: [String!]!
    $versionId: Int
  ) {
    programWithPayload(
      input: {
        programSlug: $programSlug
        organizationSlug: $organizationSlug
        sections: $sections
        versionId: $versionId
      }
    ) {
      program {
        id
        name
        payload
        orgId
      }
      interactions {
        id
        name
        payload
      }
    }
  }
`;
