import { getApolloClient } from '@drugfreesleep/modules/graphql/apollo';
import { NoteAtVersionDocument } from '@drugfreesleep/modules/graphql/generated-types';
import { useMemo } from 'react';

import { AppConfig } from './AppConfig';

export interface WebsiteConfig {
  program: {
    id: number;
    versionId?: number;
    slug: string;
    organizationSlug: string;
  };
  components: {
    [key: string]: { id: number; versionId?: number };
  };
}

export const useConfig = (): WebsiteConfig => {
  const config = useMemo(() => {
    let innerConfig;
    try {
      const client = getApolloClient();
      const data = client.readQuery({
        query: NoteAtVersionDocument,
        variables: {
          id: AppConfig.configNoteId,
        },
      });
      innerConfig = data?.note.payload;
      if (!innerConfig) {
        throw new Error(`No config found; please check static props`);
      }

      // Overrides from localStorage

      if (typeof window !== 'undefined') {
        const localConfig = window.localStorage.getItem('localConfig')
          ? JSON.parse(window.localStorage.getItem('localConfig') as string)
          : null;
        if (localConfig && localConfig.program) {
          innerConfig = localConfig;
          // eslint-disable-next-line no-console
          // console.log('Using local config');
        } else {
          // eslint-disable-next-line no-console
          console.log(
            'Local config not found, setting local from fetched config'
          );
          window.localStorage.setItem(
            'localConfig',
            JSON.stringify(innerConfig)
          );
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'Error accessing local config, falling back to fetched config:',
        error
      );
    }
    return innerConfig;
  }, []);

  return config;
};
