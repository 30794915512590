'use client';

/* eslint-disable no-console */

import { createContext, useContext } from 'react';

export interface IAuthContext {
  user: null | undefined | { id: number; username: string };
  logout: (toHomepage?: boolean) => void;
  authenticated: boolean;
  jwt?: null | string;
  login: (user: IAuthContext['user'], jwt: string) => void;
  loading: boolean;
  expired: boolean;
  setExpired?: (expired: boolean) => void;
  mode?: 'light' | 'dark';
  toggleMode: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  logout: () => null,
  authenticated: false,
  jwt: null,
  login: () => null,
  loading: true,
  expired: false,
  mode: 'light',
  toggleMode: () => null,
});

export function useAuth() {
  return useContext(AuthContext);
}
